export const generateRandomBetween = (min, max, exclude) => {
  let num = Math.floor(Math.random() * (max - min)) + min;
  if (num === exclude) {
    num = generateRandomBetween(min, max, exclude);
  }
  return num;
};

export const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(', ');
  const listEnd = arr.slice(-1);
  const conjunction = arr.length <= 1 ? '' : useOxfordComma && arr.length > 2 ? ', and ' : ' and ';

  return [listStart, listEnd].join(conjunction);
};

export const playAudio = (audio) => {
  return new Promise((res) => {
    audio.play();
    audio.onended = res;
  });
};
