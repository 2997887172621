import { useRef, useCallback } from 'react';
import ReactCanvasConfetti from "react-canvas-confetti";

const Celebrate = () => {
    const randomInRange = (min, max) => {
        return Math.random() * (max - min) + min;
    }
    function getAnimationSettings(originXA, originXB) {
        return {
            startVelocity: 10,
            spread: 250,
            ticks: 60,
            zIndex: 2,
            gravity: 1,
            particleCount: 30,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2
            }
        };
    }
    const refAnimationInstance = useRef(null);
    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);
    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
            refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
        }
    }, []);
    setInterval(nextTickAnimation, 1000);
    const canvasStyles = {
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
        height: "60%",
        top: 0,
        left: 0
    };

    return (
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    );
}
export default Celebrate;