import React, { createContext, useContext } from 'react';
import { useState, useEffect } from 'react';
import { defaultMaxGameSet, defaultNegativeMultiplier, defaultTrumps, defaultPacks } from '../utils/constants';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
    const [trumpIndex, setTrumpIndex] = useState(-1)
    const initialSetup = {
        players: [],
        negativeMultiplier: defaultNegativeMultiplier,
        trumps: defaultTrumps,
        noOfPacks: defaultPacks,
        maxGamesSet: defaultMaxGameSet,
    }
    const initialPlay = {
        players: [],
        currentGame: {},
        games: [],
        maxGamesSet: defaultMaxGameSet,
        maxNoOfCards: 0,
        currentGameNo: 0,
        currentNoOfCards: 0,
        increaseCards: false,
        currentTrump: null,
        currentFirstPlayer: null,
        currentLastPlayer: null,
    };
    const fetchLocalStoredData = (key) => {
        const stringValue = localStorage.getItem(key);
        if (stringValue) {
            try {
                return JSON.parse(stringValue);
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    }
    const [setup, setSetup] = useState(fetchLocalStoredData('setup') || initialSetup);
    const [play, setPlay] = useState(fetchLocalStoredData('play') || initialPlay);
    const [history, setHistory] = useState({});

    useEffect(() => {
        if (setup) {
            localStorage.setItem('setup', JSON.stringify(setup))
        }
    }, [setup]);

    useEffect(() => {
        if (play) {
            localStorage.setItem('play', JSON.stringify(play))
        }
    }, [play]);

    useEffect(() => {
        if (play.players.length > 0) {
            setPlay({
                ...play,
                maxNoOfCards: Math.floor(setup.noOfPacks * 52 / play.players.length)
            })
        }
    }, [play.players])

    const calculateNoOfCards = (play) => {
        const lastGameCards = play.currentNoOfCards;
        const lastGameIncrease = play.increaseCards;
        if (lastGameCards === 0) {
            // start game
            return {
                nextCard: play.maxNoOfCards,
                increase: false,
            }
        } else {
            // perform logic
            if (lastGameCards === play.maxNoOfCards && lastGameIncrease) {
                return {
                    nextCard: play.maxNoOfCards,
                    increase: false
                }
            } else if (lastGameCards === 1 && !lastGameIncrease) {
                return {
                    nextCard: 1,
                    increase: true
                }
            } else if (lastGameCards === 1 && lastGameIncrease) {
                return {
                    nextCard: 2,
                    increase: lastGameIncrease
                }
            } else if (lastGameCards === play.maxNoOfCards && !lastGameIncrease) {
                return {
                    nextCard: play.maxNoOfCards - 1,
                    increase: lastGameIncrease
                }
            } else if (lastGameIncrease) {
                return {
                    nextCard: play.currentNoOfCards + 1,
                    increase: play.increaseCards
                }
            } else if (!lastGameIncrease) {
                return {
                    nextCard: play.currentNoOfCards - 1,
                    increase: play.increaseCards
                }
            }
        }
    }

    const calculateFirstAndLastPlayer = (play) => {
        let fplayer, lplayer;
        if (!play.currentFirstPlayer) {
            fplayer = play.players[0];
            lplayer = play.players[play.players.length - 1];
        }
        if (play.currentFirstPlayer) {
            if (play.players.indexOf(play.currentFirstPlayer) !== play.players.length - 1) {
                fplayer = play.players[play.players.indexOf(play.currentFirstPlayer) + 1]
                lplayer = play.players[play.players.indexOf(play.currentFirstPlayer)]
            } else {
                fplayer = play.players[0];
                lplayer = play.players[play.players.length - 1];
            }
        }
        return { fplayer, lplayer }
    }

    const newGame = () => {
        const { nextCard, increase } = calculateNoOfCards(play);
        const { fplayer, lplayer } = calculateFirstAndLastPlayer(play);
        const currentGame = play.currentGame;
        const negativeMultiplier = setup.negativeMultiplier;
        for (var updatePlayer of Object.keys(play.currentGame)) {
            const plyr = play.currentGame[updatePlayer]
            play.currentGame[updatePlayer].score = plyr.won ? ((plyr.hands === 0) ? 10 : plyr.hands * 10) : ((plyr.hands === 0) ? negativeMultiplier : plyr.hands * negativeMultiplier)
            play.currentGame[updatePlayer].gameNo = play.currentGameNo
        }
        const currentTrumpIndex = (trumpIndex === setup.trumps.length - 1) ? 0 : trumpIndex + 1
        setTrumpIndex(currentTrumpIndex)
        const playerStat = {}
        const indexOfFirstPlayer = play.players.indexOf(fplayer);
        for (var i = indexOfFirstPlayer; i < (indexOfFirstPlayer + play.players.length); i++) {
            const newIndex = i > play.players.length - 1 ? i - (play.players.length) : i;
            playerStat[play.players[newIndex]] = {
                hands: 0,
                won: true,
                score: 0,
                judged: false,
                gameNo: 0,
                lastPlayer: play.players[newIndex] === lplayer,
                trump: setup.trumps[currentTrumpIndex],
                cards: nextCard,
            }
        }
        setPlay({
            ...play,
            currentGameNo: play.currentGameNo + 1,
            currentNoOfCards: nextCard,
            increaseCards: increase,
            games: Object.keys(currentGame).length > 0 ? [...play.games, currentGame] : [],
            currentGame: playerStat,
            currentTrump: setup.trumps[currentTrumpIndex],
            currentFirstPlayer: fplayer,
            currentLastPlayer: lplayer,
        })
    }

    const initiateGame = () => {
        setTrumpIndex(-1)
        setPlay(initialPlay)
    }

    return (
        <GameContext.Provider
            value={{
                setup,
                setSetup,
                play,
                setPlay,
                history,
                setHistory,
                newGame,
                initiateGame
            }}
        >
            {children}
        </GameContext.Provider>
    );
};

export const useGameContent = () => {
    const context = useContext(GameContext);
    if (typeof context === 'undefined') {
        throw new Error('The `useGameContent` is accesible only under GameProvider');
    }
    return context;
};