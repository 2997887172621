import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { useMantineTheme } from '@mantine/core';
import { useGameContent } from '../../contexts/GameContext';
import { getRandomColorBasedOnTheme } from "../../utils/colors";
import GradientText from "../../components/GradientText"

const Home = () => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { play, initiateGame } = useGameContent()

    const navigateToSetup = () => {
        navigate('/setup')
    }

    const navigateToStart = () => {
        initiateGame()
        navigate('/setup-players')
    }

    const navigateToGame = () => {
        navigate('/game')
    }

    const btnBgClr = () => `linear-gradient(to left, ${getRandomColorBasedOnTheme(theme.colorScheme)}, ${getRandomColorBasedOnTheme(theme.colorScheme)})`

    return (
        <div className="home-page">
            <button className='button' style={{ border: '2px solid', borderImageSlice: '1', borderImageSource: btnBgClr() }} onClick={() => navigateToSetup()}><GradientText style={{ fontSize: '2rem' }}>Setup</GradientText></button>
            <button className='button' style={{ border: '2px solid', borderImageSlice: '1', borderImageSource: btnBgClr() }} onClick={() => navigateToStart()}><GradientText style={{ fontSize: '2rem' }}>Start</GradientText></button>
            {play && play.games && play.games.length > 0 && 
                <button className='button' style={{ border: '2px solid', borderImageSlice: '1', borderImageSource: btnBgClr() }} onClick={() => navigateToGame()}><GradientText style={{ fontSize: '2rem' }}>Continue</GradientText></button>
            }
        </div>
    )
}

export default Home;