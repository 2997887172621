import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { GameProvider } from './contexts/GameContext';
import { themes } from './utils/constants';
import MobileContainer from './layouts/MobileContainer';
import Header from './layouts/Header';
import Content from './layouts/Content';
import Home from './views/Home';
import Setup from './views/Setup';
import SetupPlayers from './views/SetupPlayers';
import Game from './views/Game';
import EndGame from './views/EndGame';

const resolver = () => ({
  variables: {
    '--mantine-color-black': 'rgb(51, 51, 51)',
  },
});

const Application = () => {
  const [theme, setTheme] = useState(themes.dark);
  return (
    <MantineProvider
      withGlobalStyles
      withCSSVariables
      withNormalizeCSS
      cssVariablesResolver={resolver}
      theme={{ colorScheme: theme }}
    >
      <MobileContainer>
        <BrowserRouter>
          <Header theme={theme} setTheme={setTheme}></Header>
          <GameProvider>
            <Content>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/setup" element={<Setup />} />
                <Route path="/setup-players" element={<SetupPlayers />} />
                <Route path="/game" element={<Game />} />
                <Route path="/end-game" element={<EndGame />} />
              </Routes>
            </Content>
          </GameProvider>
        </BrowserRouter>
      </MobileContainer>
    </MantineProvider>
  );
};

export default Application;
