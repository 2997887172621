import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import logo from '../../assets/images/logo.svg';
import { themes } from '../../utils/constants';
import { UilSunset } from '@iconscout/react-unicons';
import { UilMoonset } from '@iconscout/react-unicons';

const Header = ({ theme, setTheme }) => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div onClick={() => navigate('/')} className="brand">
        <img className="logo" src={logo} alt="logo" />
        <h1>Judgement Tracker</h1>
      </div>
      <div>
        {theme === themes.dark && (
          <UilSunset
            className="theme-icon"
            size="32"
            onClick={() => setTheme(themes.light)}
          />
        )}
        {theme === themes.light && (
          <UilMoonset
            className="theme-icon"
            size="32"
            onClick={() => setTheme(themes.dark)}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
