import React from 'react';
import './index.css';
import { generateRandomBetween } from '../../utils/utils';

const LockScreen = React.memo(({ children, ...props }) => {
    const cards = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'K', 'Q', 'A'];
    const trumps = ['heart', 'spade', 'diamond', 'club'];

    const randomCard = () => {
        return (
            <div className="line">
                <div className="img card" card={cards[generateRandomBetween(0, cards.length - 1)]} suit={trumps[generateRandomBetween(0, trumps.length - 1)]} />
            </div>
        );
    };

    const cardsUIAry = [randomCard(), randomCard(), randomCard(), randomCard(), randomCard()];

    return (
        <div className="fullscreen-container">
            <div className="images-line">
                {[1, 2, 3, 4, 5].map((num) => {
                    return <React.Fragment key={num}>{cardsUIAry[generateRandomBetween(0, cardsUIAry.length - 1)]}</React.Fragment>
                })}
            </div>
            <div className="images-line">
                {[1, 2, 3, 4, 5].map((num) => {
                    return <React.Fragment key={num}>{cardsUIAry[generateRandomBetween(0, cardsUIAry.length - 1)]}</React.Fragment>
                })}
            </div>
            <div className="images-line">
                {[1, 2, 3, 4, 5].map((num) => {
                    return <React.Fragment key={num}>{cardsUIAry[generateRandomBetween(0, cardsUIAry.length - 1)]}</React.Fragment>
                })}
            </div>
        </div>
    );
});

export default LockScreen;
