import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/Droppable'
import GradientText from '../../components/GradientText';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { useDisclosure } from '@mantine/hooks';
import { Text, Button, Input, Modal, Notification, Table, Alert } from '@mantine/core';
import { UilPlusCircle, UilAlignCenterV, UilTrashAlt, UilPlayCircle, UilExclamationTriangle } from '@iconscout/react-unicons';
import { useGameContent } from "../../contexts/GameContext";
import { defaultTrumps } from '../../utils/constants';

const SetupPlayers = () => {
    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const navigate = useNavigate();
    const { setup, play, setPlay, newGame } = useGameContent()

    const [valid, setValid] = useState(true)
    const [allTrumps, setAllTrumps] = useState(true)

    const [player, setPlayer] = useState('')
    const [players, setPlayers] = useState()

    useEffect(() => {
        if (setup) {
            setPlayers(setup.players)
            setPlay({
                ...play,
                maxGamesSet: setup.maxGamesSet
            })
            setAllTrumps(setup.trumps.length === defaultTrumps.length)
        }
    }, [setup])

    useEffect(() => {
        const newPlay = {
            ...play,
            players: players
        }
        players && setPlay(newPlay)
        // eslint-disable-next-line
    }, [players, setPlay])

    const addPlayer = () => {
        if ((/^[a-zA-Z][a-zA-Z0-9]{2,15}$/.test(player)) && !players.includes(player)) {
            setPlayers([...players, player])
            closeModal()
            setPlayer('')
        }
    }
    const removePlayer = (rplayer) => {
        setPlayers(players.filter(cplayer => cplayer !== rplayer))
    }

    const playGame = () => {
        if (players.length > 2) {
            setValid(true)
            newGame()
            navigate('/game')
        } else {
            setValid(false)
        }
    }

    const onDragEnd = e => {
        if (!e.destination) {
            return;
        }
        const sorted = reorder(players, e.source.index, e.destination.index);
        setPlayers(sorted);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return (
        <div className="setup-page">
            <h1 className="content-header">Setup Players</h1>
            {!valid &&
                <Alert className='margin-vertical' icon={<UilExclamationTriangle />} title="Error!" color="red">
                    Please add atleast 3 players to start game!
                </Alert>
            }

            <Alert className='margin-vertical' variant="light" color="blue" title="Note">
                <Text><li>Playing with {setup.noOfPacks} pack of cards</li></Text>
                {!allTrumps && <div><li>
                    You have selected only below trumps to play the game! Go to Setup page, if you wish to update!
                    <div style={{ fontWeight: 600 }}><GradientText>{setup.trumps.join(', ')}</GradientText></div>
                </li></div>
                }
            </Alert>
            <Button variant='light' leftIcon={<UilPlusCircle />} onClick={openModal}>Player</Button>
            {play.players && play.players.length > 0 &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <StrictModeDroppable droppableId="Table">
                        {provided => (
                            <Table withBorder className='margin-vertical' {...provided.droppableProps} ref={provided.innerRef}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style={{ width: '70%' }}>Name</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    play.players.map((player, index) => (
                                        <Draggable
                                            key={player}
                                            draggableId={player}
                                            index={index}
                                        >
                                            {provided => (
                                                <tr key={player} ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <td><UilAlignCenterV /></td>
                                                    <td>{player}</td>
                                                    <td><UilTrashAlt style={{ cursor: 'pointer' }} onClick={() => removePlayer(player)} /></td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                    {provided.placeholder}</tbody>
                            </Table>
                        )}
                    </StrictModeDroppable>
                </DragDropContext>
            }
            <Modal opened={opened} onClose={closeModal} title="Add Player" centered>
                <Notification title="Info">
                    Enter player name and click on Add button. You can add a frequent player in the Setup screen.
                </Notification>
                <Input data-autofocus className='margin-vertical' value={player}
                    onChange={(e) => setPlayer(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && addPlayer()} />
                <Button variant='light' leftIcon={<UilPlusCircle />} onClick={() => addPlayer()}>Add</Button>
            </Modal>

            {
                play.players && play.players.length > 0 &&
                <Button variant='light' leftIcon={<UilPlayCircle />} onClick={() => playGame()} className='play-game-btn margin-vertical-4'>Play</Button>
            }

        </div >
    )
}

export default SetupPlayers;