import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { Flex, Table, Title, Group, Button, useMantineTheme } from '@mantine/core';
import { useGameContent } from '../../contexts/GameContext';
import GradientText from '../../components/GradientText';
import Celebrate from '../../components/Celebrate';
import { makeCommaSeparatedString } from '../../utils/utils';
import Hearts from '../../assets/images/hearts.png';
import Spades from '../../assets/images/spades.png';
import Diamonds from '../../assets/images/diamonds.png';
import Clubs from '../../assets/images/clubs.png';
import CutTrump from '../../assets/images/cut.png';

const EndGame = () => {
  const navigate = useNavigate();
  const { play } = useGameContent();
  const theme = useMantineTheme();
  const [consolidatedResult, setConsolidatedResult] = useState([]);

  useEffect(() => {
    if (play.players.length === 0) {
      navigate('/')
    }
  }, [play, navigate])

  const compareByScore = (plyrStat1, plyrStat2) => {
    if (plyrStat1.score < plyrStat2.score) {
      return 1;
    }
    if (plyrStat1.score > plyrStat2.score) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    if (play.games) {
      const consolidatedRecord = {};
      const filterGames = play.games.filter(
        (game) => Object.keys(game).length > 0
      );
      filterGames.forEach((game) => {
        Object.keys(game).forEach((plyr) => {
          if (consolidatedRecord[plyr]) {
            consolidatedRecord[plyr] = {
              player: plyr,
              score: consolidatedRecord[plyr].score + game[plyr].score,
              winCount:
                consolidatedRecord[plyr].winCount + (game[plyr].won ? 1 : 0),
              loseCount:
                consolidatedRecord[plyr].loseCount + (!game[plyr].won ? 1 : 0),
              winScore:
                consolidatedRecord[plyr].winScore +
                (game[plyr].won ? game[plyr].score : 0),
              loseScore:
                consolidatedRecord[plyr].loseScore +
                (!game[plyr].won ? game[plyr].score : 0),
            };
          } else {
            consolidatedRecord[plyr] = {
              player: plyr,
              score: game[plyr].score,
              winCount: game[plyr].won ? 1 : 0,
              loseCount: !game[plyr].won ? 1 : 0,
              winScore: game[plyr].won ? game[plyr].score : 0,
              loseScore: !game[plyr].won ? game[plyr].score : 0,
            };
          }
        });
      });
      // Sort based on score and set in setConsolidatedResult
      try {
        const sorted = Object.keys(consolidatedRecord)
          .map((plyr) => {
            return { ...consolidatedRecord[plyr] };
          })
          .sort(compareByScore);
        sorted[0].rank = 1;
        for (let i = 1; i < sorted.length; i++) {
          if (sorted[i].score === sorted[i - 1].score) {
            sorted[i].rank = sorted[i - 1].rank;
          }
          else {
            sorted[i].rank = sorted[i - 1].rank + 1;
          }
        }
        setConsolidatedResult(sorted);
      } catch (e) {
        // alert('Score', JSON.stringify(consolidatedRecord));
      }
    }
  }, [play.games]);

  const showCardFromName = (trump, size) => {
    const cards = {
        Hearts: <img src={Hearts} alt="hearts" style={{ width: size ? size : '40px' }} />,
        Spades: <img src={Spades} alt="spades" style={{ width: size ? size : '40px' }} />,
        Diamonds: <img src={Diamonds} alt="diamonds" style={{ width: size ? size : '40px' }} />,
        Clubs: <img src={Clubs} alt="clubs" style={{ width: size ? size : '40px' }} />,
        Cut: <img src={CutTrump} alt="clubs" style={{ width: size ? size : '40px' }} />,
    };
    return cards.hasOwnProperty(trump) ? cards[trump] : trump;
};

  return (
    <div className="end-page">
      <Flex align={'center'} justify={'space-between'}>
        <Title order={2}>Scores</Title>
        <h3>
          Games played:{' '}
          {play.games.filter((game) => Object.keys(game).length > 0).length}
        </h3>
      </Flex>
      {consolidatedResult && consolidatedResult.length > 0 && (
        <Flex>
          <GradientText style={{ fontSize: '1.4rem', fontWeight: 600 }}>
            Winner: {makeCommaSeparatedString(consolidatedResult.filter(result => result.rank === 1).map(winner => winner.player))}
          </GradientText>
          <Celebrate />
        </Flex>
      )}
      <Table verticalSpacing="md">
        <thead>
          <tr className="wordwrap">
            <th>Name</th>
            <th>Score</th>
            <th>Rank</th>
            <th>Win/Lose Count</th>
            <th>Win/Lose Score</th>
          </tr>
        </thead>
        <tbody>
          {consolidatedResult &&
            consolidatedResult.map((player, index) => {
              return (
                <tr key={index} className="wordwrap">
                  <td>{player.player}</td>
                  <td>{player.score}</td>
                  <td>{player.rank}</td>
                  <td>{player.winCount}/{player.loseCount}</td>
                  <td>{player.winScore}/{player.loseScore}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Group mt="1.5rem" position="right">
        <Button onClick={() => navigate('/game')}>Back</Button>
        <Button onClick={() => navigate('/')}>Home</Button>
      </Group>
      <Flex mt="1.5rem" direction='column' style={{overflowX: 'auto'}}>
      <GradientText style={{ fontSize: '1.4rem', fontWeight: 600 }} order={4} ta="center">Scorecard</GradientText>
      <div className='table-wrapper'>
        <Table id="sticky" verticalSpacing="sm">
          <thead>
            <tr>
              <th style={{background: theme.colorScheme === 'dark' ? '#1A1B1E' : 'white'}}>Player</th>
              {play.games.map((game, index) => (
                <th key={index} style={{fontSize: '14px', textAlign: 'center'}}>
                  <div>#{game[Object.keys(game)[0]].gameNo}</div>
                  <div>{showCardFromName(game[Object.keys(game)[0]].trump, '20px')}</div>
                  <div>Cards: {game[Object.keys(game)[0]].cards}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {
            play.players.map((player) => (
              <tr key={player}>
                <td key={player} style={{borderBottom: '1px solid', background: theme.colorScheme === 'dark' ? '#1A1B1E' : 'white'}}>{player}</td>
                {
                  play.games.map((game, index) => (
                    <td key={index} style={{color: theme.colorScheme === 'dark' ? 'rgb(193, 194, 197)' : 'white', textAlign: 'center', background: game[player].won ? 'rgb(30 100 51)' : 'rgb(146 0 7)'}}>
                      <div>{game[player].score}</div>
                      <div>({game[player].hands})</div>
                    </td>
                  ))
                }
              </tr>
            ))
          }
          </tbody>
        </Table>
      </div>
      </Flex>
    </div>
  );
};

export default EndGame;
