import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { Carousel } from '@mantine/carousel';

import { Group, Affix, Switch, Table, Badge, Text, useMantineTheme, Button, Modal, Flex, Card, Transition, rem } from '@mantine/core';
import { UilStopCircle, UilCheckCircle, UilTimesCircle, UilPlay, UilLock, UilEdit, UilInfoCircle, UilMultiply } from '@iconscout/react-unicons';
import Hearts from '../../assets/images/hearts.png';
import Spades from '../../assets/images/spades.png';
import Diamonds from '../../assets/images/diamonds.png';
import Clubs from '../../assets/images/clubs.png';
import CutTrump from '../../assets/images/cut.png';
import { useGameContent } from '../../contexts/GameContext';
import { getRandomColorBasedOnTheme } from '../../utils/colors';
import LockScreen from '../../components/LockScreen';

const Game = () => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const { play, setPlay, newGame } = useGameContent();
    const [lastPlayerCompulsory, setLastPlayerCompulsory] = useState();
    const [gameInfoModal, setGameInfoModal] = useState(false);
    const [openEnterScoreModal, setOpenEnterScoreModal] = useState(false);
    const [openLockModal, setOpenLockModal] = useState(false);
    const [endGameModal, setEndGameModal] = useState(false);
    const [currentSum, setCurrentSum] = useState();
    const [backgroundColor, setBackgroundColor] = useState(`linear-gradient(135deg,${getRandomColorBasedOnTheme} 0%,${getRandomColorBasedOnTheme} 100%)`);
    useEffect(() => {
        setBackgroundColor(`linear-gradient(135deg,${getRandomColorBasedOnTheme(theme.colorScheme)} 0%,${getRandomColorBasedOnTheme(theme.colorScheme)} 100%)`);
    }, [theme]);

    useEffect(() => {
        if (play.players.length === 0) {
            navigate('/');
        }
    }, [play, navigate]);

    useEffect(() => {
        const currentSum = Object.values(play.currentGame).reduce((sum, playerStats) => sum + playerStats.hands, 0);
        if (typeof currentSum === 'number') {
            setCurrentSum(currentSum);
            setLastPlayerCompulsory(play.currentNoOfCards - currentSum);
        }
    }, [play.currentNoOfCards, play.currentGame]);

    const initiateNextGame = () => {
        setOpenEnterScoreModal(false);
        setGameInfoModal(false);
        newGame();
    };

    const selectHandForPlayer = (hands, player) => {
        if (document) {
            // Auto moving to next slide when answer is selected
            const ctrl = document.getElementsByClassName('mantine-Carousel-controls');
            const nextCrslBtn = ctrl && ctrl.length !== 0 && ctrl[0]?.childNodes && ctrl[0]?.childNodes.length === 2 ? ctrl[0]?.childNodes[1] : null;
            if (nextCrslBtn) {
                nextCrslBtn.click();
            }
        }
        setPlay({
            ...play,
            currentGame: {
                ...play.currentGame,
                [player]: {
                    ...play.currentGame[player],
                    hands: hands,
                    judged: true,
                },
            },
        });
    };

    const updateWinStat = (player, won) => {
        setPlay({
            ...play,
            currentGame: {
                ...play.currentGame,
                [player]: {
                    ...play.currentGame[player],
                    won: won,
                },
            },
        });
    };

    const showCardFromName = (trump, size) => {
        const cards = {
            Hearts: <img src={Hearts} alt="hearts" style={{ width: size ? size : '40px' }} />,
            Spades: <img src={Spades} alt="spades" style={{ width: size ? size : '40px' }} />,
            Diamonds: <img src={Diamonds} alt="diamonds" style={{ width: size ? size : '40px' }} />,
            Clubs: <img src={Clubs} alt="clubs" style={{ width: size ? size : '40px' }} />,
            Cut: <img src={CutTrump} alt="clubs" style={{ width: size ? size : '40px' }} />,
        };
        return cards.hasOwnProperty(trump) ? cards[trump] : trump;
    };

    const handlePlayerHandClick = (player, lastPlayer, play, i) => {
        if (!(player === lastPlayer && lastPlayerCompulsory === play.currentNoOfCards - i)) {
            selectHandForPlayer(play.currentNoOfCards - i, player);
        }
    };

    return (
        <div className="setup-page">
            <Flex align={'center'} justify={'space-between'} my={'10px'}>
                <div>
                    <Flex align={'center'}>
                        <h2 style={{ margin: '0' }}>{play.currentNoOfCards} Cards</h2>
                        <h2
                            style={{
                                margin: '0 1rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {showCardFromName(play.currentTrump, '30px')}
                        </h2>
                    </Flex>
                </div>
                <Flex direction={'row'} align={'center'}>
                    {!openLockModal && <UilLock className="lock-screen" onClick={() => currentSum !== play.currentNoOfCards && setOpenLockModal(true)} />}
                    <UilInfoCircle size="30px" onClick={() => setGameInfoModal(!gameInfoModal)} />
                </Flex>
            </Flex>
            <Flex align={'center'} justify={'space-between'} my={'10px'}>
                <Flex className="game-info">
                    <div>First: {play.currentFirstPlayer}</div>
                    <div>Current Count: {Object.values(play.currentGame).reduce((sum, playerStats) => sum + playerStats.hands, 0)}</div>
                </Flex>
                <Flex className="game-info" direction={'column'} align={'end'}>
                    <div>Game#: {play.currentGameNo}</div>
                </Flex>
            </Flex>
            <div>
                {play && play.players.length > 0 && (
                    <Carousel slideSize="70%" slideGap="xs" controlsOffset="sm" loop={true} withControls={true} withIndicators>
                        {Object.keys(play.currentGame).map((player, index) => {
                            const playerStats = play.currentGame[player];
                            const lastPlayer = play.currentLastPlayer;
                            return (
                                <Carousel.Slide key={index} style={{ cursor: 'grab', minWidth: '300px' }}>
                                    <Card shadow="sm" padding="lg" radius="md" withBorder style={{ color: theme.colorScheme === 'dark' ? 'black' : 'white', background: backgroundColor }}>
                                        <Card.Section withBorder inheritPadding py="xs">
                                            <Text align="center" fw={600} style={{ fontSize: '1.5rem' }}>
                                                {player}
                                            </Text>
                                        </Card.Section>
                                        <Card.Section mt="sm">
                                            <div className="hands-header"></div>
                                            <Flex mt={'10px'} mb={'4rem'} maw={'90%'} m={'auto'} gap="md" direction="row" wrap="wrap" justify={'space-evenly'}>
                                                {[...Array(play.currentNoOfCards + 1)].map((e, i) => (
                                                    <div onClick={() => handlePlayerHandClick(player, lastPlayer, play, i)} className={`calls ${playerStats.hands === play.currentNoOfCards - i ? 'selected' : ''} ${player === lastPlayer && lastPlayerCompulsory === play.currentNoOfCards - i ? 'disabled' : ''}`} key={i}>
                                                        <Text fw={600}>{play.currentNoOfCards - i}</Text>
                                                    </div>
                                                ))}
                                            </Flex>
                                        </Card.Section>
                                    </Card>
                                </Carousel.Slide>
                            );
                        })}
                    </Carousel>
                )}
            </div>
            <Modal overlayProps={{ opacity: 0.55, blur: 3 }} opened={gameInfoModal} onClose={() => setGameInfoModal(false)} title={`Game Info (${play.currentGameNo})`} centered>
                <Flex align={'center'}>
                    <UilInfoCircle style={{ margin: '0 10px' }} />{' '}
                    <h3>
                        {play.currentNoOfCards} Cards, Trump: {showCardFromName(play.currentTrump)}
                    </h3>
                </Flex>
                <Badge style={{ height: '2.5rem', margin: '5px 15px', fontSize: '0.8rem' }}>First: {play.currentFirstPlayer}</Badge>
                <Badge style={{ height: '2.5rem', margin: '5px 15px', fontSize: '0.8rem' }}>Last: {play.currentLastPlayer}</Badge>
                <Badge style={{ height: '2.5rem', margin: '5px 15px', fontSize: '0.8rem' }}>Current Count: {Object.values(play.currentGame).reduce((sum, playerStats) => sum + playerStats.hands, 0)}</Badge>

                <Table m={'1rem'}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '10px' }}>Name</th>
                            <th style={{ textAlign: 'left', padding: '10px' }}>Hands</th>
                            <th style={{ textAlign: 'left', padding: '10px' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(play.currentGame).map((player, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ margin: '10px 0' }}>{player}</td>
                                    <td>{play.currentGame[player].hands}</td>
                                    <td>{play.currentGame[player].judged ? 'Judged' : 'Pending'}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Modal>
            <Modal overlayProps={{ opacity: 0.55, blur: 3 }} className="lockscreen-modal" opened={openLockModal} onClose={() => setOpenLockModal(false)} centered withCloseButton={false}>
                <Flex style={{ cursor: 'pointer', marginBottom: '10px' }} align={'end'} justify={'end'} width="100%" onClick={() => setOpenLockModal(false)}>
                    <UilMultiply />
                </Flex>
                <div className="fullscreen">
                    <LockScreen />
                    <div className="lockscreen-btns">
                        <Button
                            leftIcon={<UilPlay size="1rem" />}
                            disabled={currentSum === play.currentNoOfCards}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: currentSum !== play.currentNoOfCards ? 'pointer' : 'not-allowed',
                            }}
                            onClick={() => {
                                if (currentSum !== play.currentNoOfCards) {
                                    setOpenEnterScoreModal(true);
                                    setOpenLockModal(false);
                                }
                            }}
                        >
                            Play
                        </Button>
                        <Button
                            leftIcon={<UilEdit size="1rem" />}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: currentSum !== play.currentNoOfCards ? 'pointer' : 'not-allowed',
                            }}
                            onClick={() => setOpenLockModal(false)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal overlayProps={{ opacity: 0.55, blur: 3 }} style={{ fontSize: '1.4rem' }} opened={openEnterScoreModal} onClose={() => setOpenEnterScoreModal(false)} title={'Game Result'} centered>
                {Object.keys(play.currentGame).map((player, index) => {
                    return (
                        <Flex m={'0.6rem 0.4rem'} align={'center'} key={index}>
                            <Text style={{ width: '70%' }} mr={'1rem'}>
                                {player} ({play.currentGame[player].hands})
                            </Text>
                            <Switch style={{ width: '30%' }} size="lg" color={theme.colorScheme === 'dark' ? 'gray' : 'dark'} checked={play.currentGame[player].won} onLabel={<UilCheckCircle color={theme.colors.teal[theme.fn.primaryShade()]} />} offLabel={<UilTimesCircle color={theme.colors.red[theme.fn.primaryShade()]} />} onChange={(event) => updateWinStat(player, event.currentTarget.checked)} />
                        </Flex>
                    );
                })}
                <Button
                    w={'80%'}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '1rem auto',
                    }}
                    onClick={initiateNextGame}
                >
                    Next Game
                </Button>
            </Modal>
            <Modal overlayProps={{ opacity: 0.55, blur: 3 }} style={{ fontSize: '1.4rem' }} opened={endGameModal} onClose={() => setEndGameModal(false)} title={'Confirm End?'} centered>
                <Text>Do you want to stop the game?</Text>
                <Group>
                    <Button
                        w={'80%'}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '10px auto 0 auto',
                        }}
                        onClick={() => setEndGameModal(false)}
                    >
                        Continue Game
                    </Button>
                    <Button
                        variant="outline"
                        w={'80%'}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0 auto',
                        }}
                        onClick={() => navigate('/end-game')}
                    >
                        Yes, Exit
                    </Button>
                </Group>
            </Modal>
            <Affix position={{ bottom: rem(20) }} w={'100%'}>
                <Transition transition="slide-up" mounted={true}>
                    {(transitionStyles) => (
                        <Group style={{ justifyContent: 'center', zIndex: 1000 }}>
                            {!openLockModal && (
                                <>
                                    <Button leftIcon={<UilStopCircle size="1rem" />} style={transitionStyles} onClick={() => setEndGameModal(true)}>
                                        End Game
                                    </Button>
                                    <Button
                                        leftIcon={<UilPlay size="1rem" />}
                                        disabled={currentSum === play.currentNoOfCards}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            cursor: currentSum !== play.currentNoOfCards ? 'pointer' : 'not-allowed',
                                        }}
                                        onClick={() => {
                                            if (currentSum !== play.currentNoOfCards) {
                                                setOpenEnterScoreModal(true);
                                                setOpenLockModal(false);
                                            }
                                        }}
                                    >
                                        Play
                                    </Button>
                                    {!openLockModal && <UilLock className="lock-screen lockscreen-icon" onClick={() => currentSum !== play.currentNoOfCards && setOpenLockModal(true)} />}
                                </>
                            )}
                        </Group>
                    )}
                </Transition>
            </Affix>
        </div>
    );
};

export default Game;
