import React, { useState, useEffect } from 'react';
import './index.css';
import { useDisclosure } from '@mantine/hooks';
import {
  Accordion,
  Button,
  Input,
  Checkbox,
  Modal,
  Notification,
  Table,
} from '@mantine/core';
import {
  UilPlusCircle,
  UilHeartSign,
  UilSpade,
  UilClub,
  UilDiamond,
  UilCreativeCommonsPd,
  UilTrashAlt,
  UilPrevious,
} from '@iconscout/react-unicons';
import { useGameContent } from '../../contexts/GameContext';
import {
  defaultMaxGameSet,
  defaultNegativeMultiplier,
  defaultTrumps,
  defaultPacks,
} from '../../utils/constants';
import { NavLink } from 'react-router-dom';

const Setup = () => {
  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { setup, setSetup } = useGameContent();

  const [player, setPlayer] = useState('');
  const [players, setPlayers] = useState([]);
  const [negativeMultiplier, setNegativeMultiplier] = useState(
    defaultNegativeMultiplier
  );
  const [maxGamesSet, setMaxGamesSet] = useState(defaultMaxGameSet);
  const [noOfPacks, setNoOfPacks] = useState(defaultPacks);
  const [trumps, setTrumps] = useState(defaultTrumps);

  useEffect(() => {
    setPlayers(setup.players);
    setNegativeMultiplier(setup.negativeMultiplier);
    setTrumps(setup.trumps);
    setNoOfPacks(setup.noOfPacks);
  }, [setup]);
  useEffect(() => {
    setSetup({
      ...setup,
      players: [...players],
    });
  }, [players, setSetup, setup]);
  useEffect(() => {
    setSetup({
      ...setup,
      negativeMultiplier: negativeMultiplier,
    });
  }, [negativeMultiplier, setSetup, setup]);
  useEffect(() => {
    setSetup({
      ...setup,
      noOfPacks: noOfPacks,
    });
  }, [noOfPacks, setSetup, setup]);
  useEffect(() => {
    setSetup({
      ...setup,
      trumps: trumps,
    });
  }, [trumps, setSetup, setup]);
  useEffect(() => {
    setSetup({
      ...setup,
      maxGamesSet: maxGamesSet,
    });
  }, [maxGamesSet, setSetup, setup]);

  const updateTrump = (selectedTrump, checked) => {
    if (!checked) {
      setTrumps(trumps.filter((trump) => trump !== selectedTrump));
    } else {
      setTrumps(
        defaultTrumps.filter(
          (trump) => trumps.includes(trump) || trump === selectedTrump
        )
      );
    }
  };
  const addPlayer = () => {
    if (
      /^[a-zA-Z][a-zA-Z0-9]{2,15}$/.test(player) &&
      !setup.players.includes(player)
    ) {
      setPlayers([...players, player]);
      closeModal();
      setPlayer('');
    }
  };
  const removePlayer = (rplayer) => {
    setPlayers(players.filter((cplayer) => cplayer !== rplayer));
  };

  return (
    <div className="setup-page">
      <h1 className="content-header">Setup</h1>
      <NavLink to={'/'}>
        <Button
          variant="light"
          leftIcon={<UilPrevious />}
          className="margin-vertical-4 font-size-3"
        >
          Back
        </Button>
      </NavLink>
      <Accordion
        variant="separated"
        transitionDuration={400}
        defaultValue="players"
      >
        <Accordion.Item value="players">
          <Accordion.Control>Players</Accordion.Control>
          <Accordion.Panel>
            <Button
              variant="light"
              leftIcon={<UilPlusCircle />}
              onClick={() => {
                openModal();
              }}
            >
              Player
            </Button>
            {players?.length > 0 && (
              <Table withBorder className="margin-vertical">
                <thead>
                  <tr>
                    <th style={{ width: '70%' }}>Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((player, index) => (
                    <React.Fragment key={index}>
                      <tr key={player}>
                        <td>{player}</td>
                        <td>
                          <UilTrashAlt
                            style={{ cursor: 'pointer' }}
                            onClick={() => removePlayer(player)}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            )}
            <Modal
              opened={opened}
              onClose={closeModal}
              title="Add Player"
              centered
            >
              <Notification title="Info">
                Add players you play with frequently. You can also add players
                while starting game.
              </Notification>
              <Input
                data-autofocus
                className="margin-vertical"
                value={player}
                onChange={(e) => setPlayer(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && addPlayer()}
              />
              <Button
                variant="light"
                leftIcon={<UilPlusCircle />}
                onClick={() => addPlayer()}
              >
                Add
              </Button>
            </Modal>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="no-of-packs">
          <Accordion.Control>No of Packs</Accordion.Control>
          <Accordion.Panel>
            <Notification title="Info">
              Total number of pack of cards used to play the game
            </Notification>
            <Input
              className="margin-vertical"
              value={noOfPacks}
              onChange={(e) =>
                setNoOfPacks(e.target.value)
              }
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="trumps">
          <Accordion.Control>Trumps</Accordion.Control>
          <Accordion.Panel>
            <Notification title="Info">
              Rounds will be generated for the selected trumps
            </Notification>
            <Checkbox
              icon={UilHeartSign}
              className="margin-vertical"
              size="md"
              label="Hearts"
              checked={setup?.trumps?.includes('Hearts')}
              onChange={(e) =>
                updateTrump(e.target.labels[0].innerHTML, e.target.checked)
              }
            />
            <Checkbox
              icon={UilSpade}
              className="margin-vertical"
              size="md"
              label="Spades"
              checked={setup?.trumps?.includes('Spades')}
              onChange={(e) =>
                updateTrump(e.target.labels[0].innerHTML, e.target.checked)
              }
            />
            <Checkbox
              icon={UilDiamond}
              className="margin-vertical"
              size="md"
              label="Diamonds"
              checked={setup?.trumps?.includes('Diamonds')}
              onChange={(e) =>
                updateTrump(e.target.labels[0].innerHTML, e.target.checked)
              }
            />
            <Checkbox
              icon={UilClub}
              className="margin-vertical"
              size="md"
              label="Clubs"
              checked={setup?.trumps?.includes('Clubs')}
              onChange={(e) =>
                updateTrump(e.target.labels[0].innerHTML, e.target.checked)
              }
            />
            <Checkbox
              icon={UilCreativeCommonsPd}
              className="margin-vertical"
              size="md"
              label="Cut"
              checked={setup?.trumps?.includes('Cut')}
              onChange={(e) =>
                updateTrump(e.target.labels[0].innerHTML, e.target.checked)
              }
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="negative-score">
          <Accordion.Control>Negative Score Multiplier</Accordion.Control>
          <Accordion.Panel>
            <Notification title="Info">
              This value will be used to as multiplier for lost games
            </Notification>
            <Input
              className="margin-vertical"
              value={negativeMultiplier}
              onChange={(e) =>
                /^-?[0-9]*$/.test(e.target.value) &&
                setNegativeMultiplier(e.target.value)
              }
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="max-game-set">
          <Accordion.Control>Maximum Game Set to Play</Accordion.Control>
          <Accordion.Panel>
            <Notification title="Info">
              This value will be used to compute max number of games to be
              played
            </Notification>
            <Input
              className="margin-vertical"
              value={maxGamesSet}
              onChange={(e) =>
                /^[1-9]{0,1}$/.test(e.target.value) &&
                setMaxGamesSet(e.target.value)
              }
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Setup;
