import { generateRandomBetween } from './utils';

export const getDarkColor = (transparency) => {
    let color = '#';
    for (var i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    if (transparency) {
        color += transparency * 100
    }
    return color;
}
export const getLightColor = (transparency) => {
    let color = '#';
    const lightcolorchars = ['8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
    for (var i = 0; i < 6; i++) {
        color += lightcolorchars[generateRandomBetween(0, lightcolorchars.length - 1)];
    }
    if (transparency) {
        color += transparency * 100
    }
    return color;
}

export const getRandomColorBasedOnTheme = (theme, transparency) => {
    return theme === 'dark' ? getLightColor(transparency) : getDarkColor(transparency)
}