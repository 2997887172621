import { Text, useMantineTheme } from '@mantine/core';
// import { lightColors, darkColors } from "../../utils/constants"
import { getLightColor, getDarkColor } from "../../utils/colors"
const GradientText = ({ children, ...props }) => {
    const theme = useMantineTheme();
    const generateRandomColor = () => theme.colorScheme === 'dark' ? getLightColor() : getDarkColor()
    return (
        <Text variant="gradient"
            gradient={{ from: generateRandomColor(), to: generateRandomColor(), deg: 45 }} {...props}>{children}</Text>
    )
}

export default GradientText;