export const themes = {
  light: 'light',
  dark: 'dark',
};
export const defaultTrumps = ['Hearts', 'Spades', 'Diamonds', 'Clubs', 'Cut'];
export const defaultNegativeMultiplier = -10;
export const defaultMaxGameSet = 2;
export const defaultPacks = 1;

export const darkColors = [
  '#FAF0E6',
  '#B9B4C7',
  '#5C5470',
  '#352F44',
  '#3F1D38',
  '#4D3C77',
  '#A2678A',
  '#E19898',
  '#8CABFF',
  '#4477CE',
  '#512B81',
  '#35155D',
  '#EFE1D1',
  '#A78295',
  '#3F2E3E',
  '#331D2C',
  '#EAB2A0',
  '#A76F6F',
  '#435B66',
  '#2D4356',
  '#84A7A1',
  '#2E8A99',
  '#1F6E8C',
  '#0E2954',
  '#CD1818',
  '#4E3636',
  '#321E1E',
  '#116D6E',
  '#DDE6ED',
  '#9DB2BF',
  '#526D82',
  '#27374D',
  '#D4ADFC',
  '#5C469C',
  '#1D267D',
  '#0C134F',
  '#B0A4A4',
  '#957777',
  '#643A6B',
  '#5F264A',
  '#F15A59',
  '#ED2B2A',
  '#D21312',
  '#070A52',
  '#F4EEE0',
  '#6D5D6E',
  '#4F4557',
  '#393646',
  '#A5D7E8',
  '#576CBC',
  '#19376D',
  '#0B2447',
  '#D27685',
  '#9E4784',
  '#66347F',
  '#37306B',
  '#CBE4DE',
  '#0E8388',
  '#2E4F4F',
  '#2C3333',
  '#3795BD',
  '#2F58CD',
  '#4E31AA',
  '#3A1078',
  '#18122B',
  '#393053',
  '#443C68',
  '#635985',
  '#B6EADA',
  '#5B8FB9',
  '#301E67',
  '#03001C',
  '#03C988',
  '#1C82AD',
  '#00337C',
  '#13005A',
  '#2C74B3',
  '#205295',
  '#144272',
  '#0A2647',
  '#E5E5CB',
  '#D5CEA3',
  '#3C2A21',
  '#1A120B',
  '#F2F7A1',
  '#46C2CB',
  '#6D67E4',
  '#453C67',
  '#E5B8F4',
  '#C147E9',
  '#810CA8',
  '#2D033B',
  '#F0CAA3',
  '#C060A1',
  '#3B185F',
  '#00005C',
  '#C69749',
  '#735F32',
  '#282A3A',
  '#000000',
  '#6B728E',
  '#50577A',
  '#474E68',
  '#404258',
  '#A3C7D6',
  '#9F73AB',
  '#624F82',
  '#3F3B6C',
  '#FB2576',
  '#3F0071',
  '#150050',
  '#000000',
  '#D8D8D8',
  '#647E68',
  '#562B08',
  '#182747',
  '#E80F88',
  '#AF0171',
  '#790252',
  '#4C0033',
  '#E94560',
  '#533483',
  '#0F3460',
  '#16213E',
  '#A77979',
  '#704F4F',
  '#553939',
  '#472D2D',
  '#E7F6F2',
  '#A5C9CA',
  '#395B64',
  '#2C3333',
  '#DCD7C9',
  '#A27B5C',
  '#3F4E4F',
  '#2C3639',
  '#E7AB79',
  '#B25068',
  '#774360',
  '#4C3A51',
  '#D6D5A8',
  '#816797',
  '#51557E',
  '#1B2430',
  '#7858A6',
  '#5B4B8A',
  '#4C3575',
  '#371B58',
  '#F806CC',
  '#A91079',
  '#570A57',
  '#2E0249',
  '#7F8487',
  '#413F42',
  '#16003B',
  '#F73D93',
  '#D49B54',
  '#C74B50',
  '#712B75',
  '#46244C',
];
export const lightColors = [
  '#FFBFBF',
  '#FFE5E5',
  '#F3FDE8',
  '#A8DF8E',
  '#BEADFA',
  '#D0BFFF',
  '#DFCCFB',
  '#FFF3DA',
  '#F1F0E8',
  '#EEE0C9',
  '#ADC4CE',
  '#96B6C5',
  '#FFD9B7',
  '#7EAA92',
  '#9ED2BE',
  '#C8E4B2',
  '#FEBBCC',
  '#FFCCCC',
  '#FFDDCC',
  '#FFEECC',
  '#FFDBAA',
  '#FFF0F5',
  '#FFD1DA',
  '#FBA1B7',
  '#DBC4F0',
  '#FFCACC',
  '#D4E2D4',
  '#FAF3F0',
  '#CCEEBC',
  '#8CC0DE',
  '#FFD9C0',
  '#FAF0D7',
  '#F8FDCF',
  '#E2F6CA',
  '#9BE8D8',
  '#78C1F3',
  '#FDFFAE',
  '#E9FFC2',
  '#C3EDC0',
  '#AAC8A7',
  '#CBFFA9',
  '#FFFEC4',
  '#FFD6A5',
  '#FF9B9B',
  '#A4907C',
  '#FBFFDC',
  '#D0F5BE',
  '#98EECC',
  '#F7FFE5',
  '#E1ECC8',
  '#C4D7B2',
  '#A0C49D',
  '#FFEEBB',
  '#A7ECEE',
  '#99DBF5',
  '#9AC5F4',
  '#967E76',
  '#D7C0AE',
  '#EEE3CB',
  '#9BABB8',
  '#F6FFA6',
  '#F3BCC8',
  '#E893CF',
  '#9376E0',
  '#F2BED1',
  '#FDCEDF',
  '#F8E8EE',
  '#F9F5F6',
  '#FBFFDC',
  '#D0F5BE',
  '#98EECC',
  '#79E0EE',
  '#F8F6F4',
  '#E3F4F4',
  '#D2E9E9',
  '#C4DFDF',
  '#E6FFFD',
  '#AEE2FF',
  '#ACBCFF',
  '#B799FF',
  '#FEA1A1',
  '#ECCDB4',
  '#F0EDD4',
  '#F9FBE7',
  '#FFF8DE',
  '#D6E8DB',
  '#C1D0B5',
  '#99A98F',
  '#AAC8A7',
  '#C9DBB2',
  '#E3F2C1',
  '#F6FFDE',
  '#FFF9DE',
  '#FFD3B0',
  '#FF6969',
  '#A6D0DD',
  '#C0DBEA',
  '#BA90C6',
  '#E8A0BF',
  '#FDF4F5',
  '#A4BC92',
  '#B3C99C',
  '#C7E9B0',
  '#DDFFBB',
  '#DAF5FF',
  '#B9E9FC',
  '#B0DAFF',
  '#FEFF86',
  '#FDF7C3',
  '#FFDEB4',
  '#FFB4B4',
  '#B2A4FF',
  '#DFA67B',
  '#F4B183',
  '#FFD966',
  '#FFF2CC',
  '#FAEDCD',
  '#FEFAE0',
  '#E9EDC9',
  '#CCD5AE',
  '#FBFFB1',
  '#FFEBB4',
  '#FFBFA9',
  '#FFACAC',
  '#EEEEEE',
  '#E8D5C4',
  '#FFF1DC',
  '#3A98B9',
  '#95BDFF',
  '#B4E4FF',
  '#DFFFD8',
  '#F7C8E0',
  '#FFF4D2',
  '#E5D1FA',
  '#E3DFFD',
  '#ECF2FF',
  '#F6E6C2',
  '#FFAACF',
  '#EA8FEA',
  '#B9F3E4',
  '#F9F5E7',
  '#F8EAD8',
  '#EDDBC7',
  '#A7727D',
  '#F8CBA6',
  '#FFE7CC',
  '#FFFBEB',
  '#ECF9FF',
  '#FDEBED',
  '#FFCEFE',
  '#D9ACF5',
  '#AAE3E2',
  '#FEDEFF',
  '#93C6E7',
  '#AEE2FF',
  '#B9F3FC',
  '#FFF2F2',
  '#E5E0FF',
  '#8EA7E9',
  '#7286D3',
];
